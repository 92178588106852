<template>
  <div class="container" v-loading="loading">
    <input type="file" hidden ref="upload" @change="uploadFile" />
    <el-dialog :visible.sync="showStudy" width="650px">
      <el-descriptions
        direction="vertical"
        :column="2"
        border
        v-if="experienceData"
      >
        <el-descriptions-item label="姓名">
          {{ experienceData.userName }}
        </el-descriptions-item>
        <el-descriptions-item label="学习心得">
          <a
            :href="url"
            target="_blank"
            v-for="(url, index) in experienceData.previewUrl"
            :key="index"
          >
            文件{{ index + 1 }}
          </a>
        </el-descriptions-item>
      </el-descriptions>
      <el-empty v-else></el-empty>
    </el-dialog>
    <div class="row info">
      <div class="pic">
        <img :src="userInfo.avatar ? userInfo.avatar : photo" alt="" />
      </div>
      <div class="m_l_30">
        <div class="fw_bold fs_18">
          {{
            userType == 1 ? "局端管理员" : userType == 2 ? "学校管理员" : "党员"
          }}
        </div>
        <div class="fs_14 c_999 p_tb_10">姓名：{{ userInfo.nick_name }}</div>
      </div>
    </div>
    <div class="row" v-show="userType != 3 && userType != ''">
      <Title title="党建工作台" style="width: 100%"></Title>
      <div class="workbench">
        <div
          v-for="item in userType == 1
            ? platesJu
            : userType == 2
            ? platesXiao
            : ''"
          :key="item.id"
          @click="toManagement(item.id)"
        >
          <div class="title">
            {{ item.title }}
          </div>
          <img :src="item.icons" class="icon" />
        </div>
      </div>
    </div>
    <div class="row">
      <Title title="我的学习" style="width: 100%"></Title>
      <div class="study">
        <div
          class="item pointer"
          v-for="i in contents.records"
          :key="i.id"
          @click="toResourceDetail_click(i)"
        >
          <div class="cover">
            <img :src="i.coverUrl" />
          </div>
          <div class="title">
            {{ i.title }}
          </div>
          <div class="btn">
            <el-button type="primary" size="mini" @click.stop="viewer(i)">
              查看学习心得
            </el-button>
          </div>
        </div>
      </div>
      <div class="paging">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="query.size"
          :background="true"
          layout="total, prev, pager, next"
          :total="contents.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { sessionGet } from "@/utils/local";
import { putFile_api } from "@/api/resourceCenter/school/center";
import {
  getPartyIndividualRecordVo,
  getPartyStudyExperience,
  updatePartyStudyExperience,
} from "@/api/party/index";
import Title from "../components/Title.vue";
import photo from "@/assets/img/avatar.png";
export default {
  name: "myCenter",
  data: () => {
    return {
      loading: false,
      showStudy: false,
      userInfo: "",
      resourceId: "",
      experienceData: [],
      photo,
      userType: "", //1局管理员2校管理员3普通党员
      contents: "",
      query: {
        userId: sessionGet("userInfo").user_id,
        size: 10,
        current: 1,
      },
      platesJu: [
        {
          title: "学习培训管理",
          icons: require("@/assets/image/Group 1023.png"),
          id: 1001,
        },
        {
          title: "学校党建管理",
          icons: require("@/assets/image/Group 1024.png"),
          id: 1002,
        },
        {
          title: "党建专题管理",
          icons: require("@/assets/image/Group 1025.png"),
          id: 1003,
        },
        {
          title: "党建资源管理",
          icons: require("@/assets/image/Group 1026.png"),
          id: 1004,
        },
        {
          title: "党风廉政管理",
          icons: require("@/assets/image/Group 1027.png"),
          id: 1005,
        },
        {
          title: "学习强国管理",
          icons: require("@/assets/image/Group 1028.png"),
          id: 1006,
        },
        {
          title: "党建动态管理",
          icons: require("@/assets/image/Group 1028.png"),
          id: 1007,
        },
      ],
      platesXiao: [
        {
          title: "学习培训管理",
          icons: require("@/assets/image/Group 1023.png"),
          id: 1008,
        },
        {
          title: "学校党建管理",
          icons: require("@/assets/image/Group 1024.png"),
          id: 1009,
        },
        {
          title: "党建党风廉政",
          icons: require("@/assets/image/Group 1027.png"),
          id: 1010,
        },
        {
          title: "党建学习强国",
          icons: require("@/assets/image/Group 1028.png"),
          id: 1011,
        },
      ],
    };
  },
  components: {
    Title,
  },
  created() {
    let userInfo = sessionGet("userInfo");
    this.userInfo = userInfo;
    //判断角色
    // 党建局端管理员 partyBuildingAdministrator
    // 党建校端管理员 partyBuildingSchoolAdministrator
    // 党建党员 partyBuildingMembers
    let roles = userInfo.role_name;
    if (roles.indexOf("partyBuildingAdministrator") >= 0) {
      this.userType = 1;
    } else if (roles.indexOf("partyBuildingSchoolAdministrator") >= 0) {
      this.userType = 2;
    } else {
      this.userType = 3;
    }
    //获取学习记录
    this.getRecords();
  },
  methods: {
    toManagement(id) {
      window.open("https://admin.wuhousmartedu.com/#/login?type=1&id=" + id);
      // window.open('http://139.155.73.40:8081/#/login?type=1&id=' + id);
    },
    async getRecords() {
      this.loading = true;
      const { data } = await getPartyIndividualRecordVo(this.query);
      this.contents = data;
      this.loading = false;
    },
    handleCurrentChange(page) {
      this.query.current = page;
      this.getRecords();
    },
    viewer(e) {
      getPartyStudyExperience({
        resourcesId: e.resourcesId,
        userId: this.userInfo.user_id,
      }).then((res) => {
        let data = res.data.records[0];
        if (data) {
          let urls = data.url.split(",");
          let previewUrl = [];
          urls.forEach((url) => {
            previewUrl.push(
              `http://139.155.146.142:8012/onlinePreview?url=${encodeURIComponent(
                btoa(url)
              )}`
            );
            data.previewUrl = previewUrl;
          });
        }
        this.experienceData = data;
        this.showStudy = true;
      });
    },
    toResourceDetail_click(data) {
      console.log(data);
      this.$router.push({
        path: "/party/trainingDetail",
        query: { id: data.resourcesId },
      });
    },
    //查看学习心得
    handleClick(row) {
      window.open(
        "http://139.155.146.142:8012/onlinePreview?url=" +
          encodeURIComponent(btoa(row.url))
      );
    },
    //编辑学习心得
    handleEdit(row) {
      this.resourceId = row.id;
      this.$refs.upload.click();
    },
    uploadFile(e) {
      let file = e.target.files[0];
      var formData = new FormData();
      formData.append("file", file);
      putFile_api(formData).then((res) => {
        if (res.code == 200) {
          updatePartyStudyExperience({
            id: this.resourceId,
            isExamine: 2,
            url: res.data.link,
            userId: sessionGet("userInfo").user_id,
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success("修改成功！");
              this.showStudy = false;
            }
          });
        }
      });
      // if(file.size<1024*1024*5){
      //   putFile_api(formData).then(res=>{
      //   if(res.code==200){
      //     this.form.coverUrl=res.data.link;
      //   }
      // })
      // }else{
      //    this.$message.warning("请上传小于5M的图片");
      // }
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: PingFang-SC;
  src: url("../../../assets/fonts/苹方黑体-中粗-简.ttf");
}

.container {
  .row {
    margin: 20px 0;
  }

  .paging {
    text-align: center;
    margin-top: 20px;
  }

  .info {
    display: flex;
    flex-direction: row;
    padding: 5px 40px;
    background: linear-gradient(
      89deg,
      rgba(255, 249, 231, 0.2) 24%,
      #fff9e7 100%,
      rgba(255, 249, 231, 0.2) 100%
    );

    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }

  .workbench {
    display: flex;
    flex-wrap: wrap;

    .title {
      position: absolute;
      left: 38px;
    }

    > div {
      position: relative;
      margin-bottom: 10px;
      margin-right: 10px;
      cursor: pointer;

      font-family: PingFang-SC;
      color: #de413e;
      display: flex;
      align-items: center;
      font-size: 18px;

      // background: linear-gradient(123deg, #fff5f4 0%, #ffb4b3 100%);

      .icon {
        width: 230px;

        height: 110px;

        // margin-left: 6px;
      }
    }
  }

  .study {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      background-color: #fff;
      width: 49.5%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cover {
        width: 156px;
        height: 88px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .title {
        flex: 1;
        text-align: left;
        padding-left: 20px;
        font-size: 16px;
      }

      .btn {
        margin-right: 20px;

        .el-button--primary {
          width: 120px;
          border-color: #fff;
          height: 32px;
          background: linear-gradient(180deg, #ffbe73, #dd2622 100%);
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
